import React, { Component } from 'react'
//import ReactCountdownClock from 'react-countdown-clock'
import './App.css'
//import LineChart from './LineChart'
import * as d3 from 'd3';
import Tooltip from 'rc-tooltip';
import Slider from 'rc-slider';

const Handle = Slider.Handle;
const Range = Slider.Range;

const handle = (props) => {
	const { value, dragging, index, ...restProps } = props;
	return (
		<Tooltip
			prefixCls="rc-slider-tooltip"
			overlay={value}
			visible={dragging}
			placement="top"
			key={index}
		>
			<Handle value={value} {...restProps} />
		</Tooltip>
	);
};

const binsMarks = {
	3: '3',
	5: '5',
	10: '10',
	20: '20',
	30: '30',
	50: '50',
	100: '100',
	150: '150',
	200: '200',
	300: '300',
	500: '500',
}

const GRAPH_WIDTH = 400; // グラフの幅
const GRAPH_HEIGHT = 300; // グラフの高さ
const GRAPH_PADDING = 40; // スケール表示用マージン
const GRAPH_YMIN = -40;
const GRAPH_YMAX = 60;
const REF = 100;
const EPS = 1e-6;

const FREQ_MIN = 0;
const FREQ_MAX = 8000;

class Home extends Component {

	constructor(props) {
		super(props)
		this.state = {
			xs: -10.0,
			xe: 10.0,
			ys: -10.0,
			ye: 10.0,
			bins: 150,
			gcount: 1,
			rootHeightRange: [40, 260],
		}
	}

	// static getDerivedStateFromProps(props, state) {
	// 	const gids = props.geList.map(pc => pc.group);
	// 	state.gcount = [...new Set(gids)].length;
	// 	return state;
	// }

	componentDidMount = () => {
		this.createDynamicLines()
	}

	componentDidUpdate() {
		this.createDynamicLines()
	}

	createDynamicLines() {// several lines
		const { geList, gwidth, gheight, margin, dmap } = this.props;
		const { xs, xe, ys, ye } = this.state;
		const vis = "#datavis"
		d3.select(vis).selectAll("*").remove()
		const svg = d3.select(vis)
			.append("svg")
			.attr("height", gheight + margin.top + margin.bottom)
			.attr("width", gwidth + margin.left + margin.right)
			.append("g")
			.attr("transform", "translate(" + margin.left + "," + margin.top + ")")
		const xScale = d3.scaleLinear()
			.domain([xs, xe])
			.range([0, gwidth]);
		const yScale = d3.scaleLinear()
			.domain([ys, ye])
			.range([gheight, 0]);
		svg.append("g")
			.attr("transform", "translate(0," + gheight + ")")
			.call(d3.axisBottom(xScale))
		svg.append("g")
			.call(d3.axisLeft(yScale))

		// console.info("Displaying GridEye Data:");
		for (var i = 0; i < geList.length; i++) {
			this.createDynamicLineN(i, gheight, xScale, yScale, svg)
		}
	}

	createDynamicLineN(n, canvasHeight, xScale, yScale, svg) {
		const { geList, dmap, minTemp, maxTemp, currentTime } = this.props;
		const ct = currentTime.getTime();
		const sdt = dmap[geList[n].deviceId];
		const { cx, cy, degx, degy, height } = sdt;

		const tdt = sdt.data[sdt.data.length - 1];

		// console.log(geList[n].deviceId, tdt);
		if (!tdt) return;

		const cg = {
			x: height * Math.tan(degx * Math.PI / 180) + cx,
			y: height * Math.tan(degy * Math.PI / 180) + cy,
		};

		const x0 = height / Math.cos((degy - 30) * Math.PI / 180) * Math.tan((degx - 30) * Math.PI / 180) + cx;
		const x1 = height / Math.cos((degy - 30) * Math.PI / 180) * Math.tan((degx + 30) * Math.PI / 180) + cx;
		const x2 = height / Math.cos((degy + 30) * Math.PI / 180) * Math.tan((degx - 30) * Math.PI / 180) + cx;
		const x3 = height / Math.cos((degy + 30) * Math.PI / 180) * Math.tan((degx + 30) * Math.PI / 180) + cx;
		const y0 = height / Math.cos((degx - 30) * Math.PI / 180) * Math.tan((degy - 30) * Math.PI / 180) + cy;
		const y1 = height / Math.cos((degx - 30) * Math.PI / 180) * Math.tan((degy + 30) * Math.PI / 180) + cy;
		const y2 = height / Math.cos((degx + 30) * Math.PI / 180) * Math.tan((degy - 30) * Math.PI / 180) + cy;
		const y3 = height / Math.cos((degx + 30) * Math.PI / 180) * Math.tan((degy + 30) * Math.PI / 180) + cy;

		const rp = [
			{ x: x0, y: y0 },
			{ x: x1, y: y2 },
			{ x: x2, y: y1 },
			{ x: x3, y: y3 },
		];
		
		const lx0 = x1 - x0;
		const lx1 = x3 - x2;
		const lxd = x2 - x0;
		const lxdd = lx0 - lx1;
		const ly0 = y1 - y0;
		const ly1 = y3 - y2;
		const lyd = y2 - y0;
		const lydd = ly0 - ly1;
		const cvBase = 192 / (maxTemp - minTemp);

		for(let i = 0; i < 8; i++){
			for(let j = 0; j < 8; j++){
				const color = tdt ? "#" + Number(31 + Math.round((tdt.temps[i*8 + (7-j)] - minTemp) * cvBase)).toString(16) + "1F1F" : "white";
				svg.append("path")
					.datum([
						{ x: x0 + (lxd * i / 8) + (lx0 - lxdd * i / 8) * j / 8, y: y0 + (lyd * j / 8) + (ly0 - lydd * j / 8) * i / 8 },
						{ x: x0 + (lxd * (i + 1) / 8) + (lx0 - lxdd * (i + 1) / 8) * j / 8, y: y0 + (lyd * j / 8) + (ly0 - lydd * j / 8) * (i + 1) / 8 },
						{ x: x0 + (lxd * (i + 1) / 8) + (lx0 - lxdd * (i + 1) / 8) * (j + 1) / 8, y: y0 + (lyd * (j + 1) / 8) + (ly0 - lydd * (j + 1) / 8) * (i + 1) / 8 },
						{ x: x0 + (lxd * i / 8) + (lx0 - lxdd * i / 8) * (j + 1) / 8, y: y0 + (lyd * (j + 1) / 8) + (ly0 - lydd * (j + 1) / 8) * i / 8 },
						{ x: x0 + (lxd * i / 8) + (lx0 - lxdd * i / 8) * j / 8, y: y0 + (lyd * j / 8) + (ly0 - lydd * j / 8) * i / 8 },
					])
					.attr("fill", color)
					.attr("stroke-width", 1)
					.attr("stroke", "gray")
					.attr("d", d3.line().x(d => xScale(d.x)).y(d => yScale(d.y)));
			}
		}


	
		svg.append("g")
			.selectAll("circle")
			.data(rp)
			.enter()
			.append("circle")
			.attr("cx", d => xScale(d.x))
			.attr("cy", d => yScale(d.y))
			.attr("fill", "steelblue")
			.attr("r", 3);

		svg.append("g")
			.selectAll("circle")
			.data([{ x: cx, y: cy }])
			.enter()
			.append("circle")
			.attr("cx", d => xScale(d.x))
			.attr("cy", d => yScale(d.y))
			.attr("fill", "blue")
			.attr("r", 4);

		// console.log(n, { cx, cy, degx, degy, height }, cg, rp);

		// Audio Spectrum Display

		const vis = `#graphvis${n}`
		d3.select(vis).selectAll("*").remove()
		const svg2 = d3.select(vis).append("svg").attr("width", GRAPH_WIDTH).attr("height", GRAPH_HEIGHT);
		const xScale2 = d3.scaleLinear()
			.domain([FREQ_MIN, FREQ_MAX])
			.range([GRAPH_PADDING, GRAPH_WIDTH - GRAPH_PADDING]);
		const yScale2 = d3.scaleLinear()
			.domain([GRAPH_YMIN, GRAPH_YMAX])
			.range([GRAPH_HEIGHT - GRAPH_PADDING, GRAPH_PADDING]);
		svg2.append("g")
			.attr("transform", "translate(" + 0 + "," + (GRAPH_HEIGHT - GRAPH_PADDING) + ")")
			.call(d3.axisBottom(xScale2));
		svg2.append("g")
			.attr("transform", "translate(" + GRAPH_PADDING + "," + 0 + ")")
			.call(d3.axisLeft(yScale2));
		const xStep = (FREQ_MAX - FREQ_MIN) / tdt.audioSpectrum.length;
		const xWidth = (GRAPH_WIDTH - 2 * GRAPH_PADDING) / tdt.audioSpectrum.length;
		svg2.append("g")
			.selectAll("rect")
			.data(tdt.audioSpectrum)
			.enter()
			.append("rect")
			.attr("x", (_, i) => xScale2(i * xStep))
			.attr("y", (d, _) => yScale2(20*Math.log10((d+EPS)/REF)))
			.attr("width", xWidth)
			.attr("height", (d) => GRAPH_HEIGHT - GRAPH_PADDING - yScale2(20*Math.log10((d+EPS)/REF)))
			.attr("fill", "steelblue");
	}

	// onChangeHeights(n, range) {
	// 	console.log('onChangeHeights', n, range);
	// 	const { heights } = this.state;
	// 	heights[n][0] = range[0];
	// 	heights[n][1] = range[1];
	// 	this.setState({ heights }); // of cource already modified, but state update is required
	// }

	// onChangeCtrlShow() {
	// 	console.log('called onChangeCtrlShow');
	// 	for (let i = 1; i <= this.state.gcount; i++) {
	// 		const current = d3.select("#ctrl" + String(i)).style("display");
	// 		console.log(current);
	// 		d3.select("#ctrl" + String(i)).style("display", current === "block" ? "none" : "block");
	// 	}
	// }

	render() {
		const { geList } = this.props;
		var graphs = []

		geList.forEach((pc, n) => {
			graphs.push(
				<div id={`graphvis${n}`} height="100px" style={{ width: '100%' }} />
			)
		});

		return (
			<div>
				<div>
					<Slider min={3} max={500} defaultValue={this.state.bins} handle={handle}
						marks={binsMarks} className="bins-slider"
						onChange={
							(v) => {
								this.setState({
									bins: v
								})
							}
						} />
				</div>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<div id={"datavis"} height="100px" style={{ flexGrow: 1 }}  />
					<div id={"graphs"} style={{ width: '400px' }}>
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							{graphs}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Home;
